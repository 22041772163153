<template>
  <div class="card" :data-state="cardState">
    <!-- <AppLoader v-if="isLoading" /> -->
    <div class="card-header">
      <div>
        <avatar
          :fullname="guardian.first_name + ' ' + guardian.last_name"
          :size="68"
          style="font-size: 24px; font-weight: 700"
          class="card-avatar-new"
          color="#01A4C0"
        ></avatar>
      </div>
      <div style="margin-top: 5px; margin-left: 20px">
        <h1 class="fullname">
          {{ guardian.first_name + " " + guardian.last_name }}
        </h1>
        <h2 class="email">{{ guardian.email }}</h2>
      </div>
    </div>
    <!-- <div class="card-header">
      <avatar
        :fullname="guardian.first_name + ' ' + guardian.last_name"
        :size="68"
        style="font-size: 24px"
        class="card-avatar font-bold shadow inline-block"
        color="#01A4C0"
      ></avatar>
      <div class="inline-block">
      <h1 class="card-fullname">
        {{ guardian.first_name + " " + guardian.last_name }}
      </h1>
      <div class="appMyProfile__container__stars card-jobtitle">
        {{ guardian.email }}
      </div>
    </div>
    </div> -->

    <!-------------------- PROFILE BUTTONS --------------------->
    <div class="card-buttons">
      <button
        @click="activate(1, '#general')"
        :class="{ isActive: active_el == 1 }"
        data-section="#general"
      >
        General
      </button>
      <button
        @click="activate(2, '#address')"
        :class="{ isActive: active_el == 2 }"
        data-section="#address"
      >
        Address
      </button>
      <button
        @click="activate(3, '#settings')"
        :class="{ isActive: active_el == 3 }"
        data-section="#settings"
      >
        Settings
      </button>
    </div>

    <!-------------------- PROFILE SECTIONS --------------------->
    <div class="card-main">
      <div
        class="card-section"
        :class="{ isActive: active_el == 1 }"
        id="general"
      >
        <div class="card-content">
          <div class="card-subtitle text-left pb-[20px] text-[#806157]">
            My Information
          </div>

          <!--------------- Username ---------------->
          <div class="input-label ml-1 text-left">Name</div>
          <div
            class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
          >
            <div>
              <!-- <div class="text-xs text-left">Name</div> -->
              <div class="text-left text-sm">
                {{ guardian.first_name }}
                {{ guardian.last_name }}
              </div>
            </div>
            <button
              @click="openSheetEditName"
              class="bg-[#49AACB] rounded-full p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
          </div>
          <!--------------- End Username ---------------->
          <div class="input-label ml-1 text-left mt-2">Phone Number</div>
          <div
            class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
          >
            <div>
              <div class="text-left text-sm">
                {{ guardian.phone_number }}
              </div>
            </div>
            <button
              @click="openSheetEditPhone"
              class="bg-[#49AACB] rounded-full p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
          </div>
          <!--------------- End Phone Number ---------------->
          <div class="input-label ml-1 text-left mt-2">Family User Name</div>
          <div
            class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
          >
            <div>
              <!-- <div class="text-xs text-left">Family User Name</div> -->
              <div class="text-left text-sm">
                {{ guardian.family.name }}
              </div>
            </div>
            <img src="../../assets/images/lock.svg" />
            <!-- <button
            disabled
              @click="openSheetEditFamilyUsername"
              class="bg-[#49AACB] rounded-full p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button> -->
          </div>
          <!--------------- End Family Name ---------------->
          <div class="input-label ml-1 text-left mt-2">Email Address</div>
          <div
            class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
          >
            <div>
              <!-- <div class="text-xs text-left">Email Address</div> -->
              <div
                class="text-left text-sm"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 200px;
                "
              >
                {{ guardian.email }}
              </div>
            </div>

            <img src="../../assets/images/lock.svg" />
          </div>
          <!--------------- End Email Address ---------------->
          <div class="input-label ml-1 text-left mt-2">Password</div>
          <div
            class="rounded-lg p-3 mb-2 flex items-center justify-between border border-[#806157]"
          >
            <div>
              <!-- <div class="text-xs text-left">Password</div> -->
              <div
                class="text-left text-sm"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 200px;
                "
              >
                **********
              </div>
            </div>

            <button
              @click="openSheetEditPassword"
              class="bg-[#49AACB] rounded-full p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </button>
          </div>
          <!--------------- End Password ---------------->

          <button
            class="bg-[#ff4f79] border-none text-white font-bold fs-[20px] py-5 px-14 rounded-full mx-auto cursor-pointer mt-6 text-sm block mb-5"
            @click="logOutMobile()"
          >
            Logout
          </button>
        </div>
      </div>
      <!-------------------- ADDRESS SECTION --------------------->
      <div
        class="card-section"
        :class="{ isActive: active_el == 2 }"
        id="address"
      >
        <div class="card-content">
          <div class="card-subtitle text-left text-[#806157] mb-9">
            Your Family Address Book
          </div>

          <div v-if="addresses.length > 0">
            <AppProfileAdress
              v-for="(address, index) in addresses"
              :key="address.id"
              :type="address.type"
              :isDefault="address.is_default"
              :adress="address.address"
              @delete="deleteAddress(address.id)"
              @edit="openEditModalAddress(index)"
            />
          </div>
          <div
            v-else
            class="shadow"
            style="
              padding: 20px;
              text-align: center;
              font-size: 12px;
              border-radius: 10px;
            "
          >
            Add a default address
          </div>

          <button
            class="bg-[#01A4C0] text-white rounded-[40px] mt-6"
            style="width: 100%; height: 51px"
            @click="openSheetAddAddress"
          >
            Add New Address
          </button>
        </div>
      </div>
      <!-------------------- SETTINGS SECTION --------------------->
      <div
        class="card-section"
        :class="{ isActive: active_el == 3 }"
        id="settings"
      >
        <div class="card-content">
          <div class="p-3 mb-2 flex items-center justify-between">
            <div>
              <div class="fs-[16px] font-bold mb-1">Toggle Notifications</div>
              <div class="text-[12px] text-[#9E9E9E] leading-tight">
                Enable notifications through the <br />
                Sonik Pocket app.
              </div>
            </div>

            <toggle-button
              :height="26"
              color="#6BB998"
              :value="$store.state.guardianEmail"
              @change="toggleNotifications()"
              :sync="true"
            />
          </div>
        </div>
      </div>
    </div>

    <Sheet :show.sync="sheetAddAddress" :on-close="closeSheetAddAddress">
      <div slot="title">Add a new address</div>
      <div>
        <AppInput
          :required="true"
          v-model="address.number"
          type="text"
          name="House Number"
          label="house_number"
          labelAlign="left"
        />

        <div
          style="
            align-self: flex-start;
            color: var(--secondary);
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 5px;
          "
        >
          <span>Postcode</span>
          <svg
            v-if="isAddressLoading"
            width="65"
            height="10"
            viewBox="0 0 120 30"
            xmlns="http://www.w3.org/2000/svg"
            fill="#757575"
          >
            <circle cx="15" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
              <animate
                attributeName="r"
                from="9"
                to="9"
                begin="0s"
                dur="0.8s"
                values="9;15;9"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="0.5"
                to="0.5"
                begin="0s"
                dur="0.8s"
                values=".5;1;.5"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="105" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>
        <vue-suggestion
          ref="suggest"
          :items="getApiAddresses"
          :itemTemplate="itemTemplate"
          :setLabel="setLabel"
          @selected="itemSelected"
          @changed="search"
          @key-down="getInputAddress"
          v-model="selectedAddress"
          style="width: 100%"
          type="text"
        ></vue-suggestion>

        <div
          style="
            align-self: flex-start;
            color: var(--secondary);
            font-size: 12px;
            margin-top: 20px;
            font-weight: 600;
            margin-bottom: 5px;
          "
        >
          Address type
        </div>
        <div class="addressTypeHolder" style="height: 50px; width: 100%">
          <select
            v-model="address.type"
            class="global-input-style"
            style="height: 48px; width: 100%; font-size: 0.9rem; color: #464a4c"
          >
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Grandma">Grandma</option>
            <option value="Grandpa">Grandpa</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div class="defaultAddress" style="margin-bottom: 20px">
          <div style="display: flex; align-items: center">
            <input
              type="checkbox"
              id="defaultAddress"
              style="margin-right: 5px"
              :val="isDefaultValue"
              v-model="address.is_default"
            />
            <label for="defaultAddress" style="font-size: 14px"
              >Make my default address</label
            >
          </div>
        </div>

        <div
          v-if="hasAddress"
          style="
            margin-top: 10px;
            align-self: flex-start;
            color: tomato;
            font-size: 12px;
          "
        >
          <p style="font-size: 14px">
            Oops! We cannot find the address you are searching for!
          </p>
          <p style="margin-top: 5px; color: #7a615a">
            Keep typing your full address and click the SAVE button.
          </p>
        </div>
      </div>

      <div class="flex justify-center mt-auto">
        <button
          @click="addAddress"
          class="bg-[#58C1E1] rounded-[100px] w-full py-2 px-8 text-white text-sm font-bold"
        >
          Save Address
        </button>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetEditName" :on-close="closeSheetEditName">
      <div slot="title">Details</div>
      <div>
        <AppInput
          maxlength="20"
          :dice="false"
          :required="true"
          v-model="editGuardian.first_name"
          type="text"
          name="First Name"
          label="first_name"
          labelAlign="left"
        />
        <AppInput
          maxlength="20"
          :dice="false"
          :required="true"
          v-model="editGuardian.last_name"
          type="text"
          name="Last Name"
          label="last_name"
          labelAlign="left"
        />
      </div>
      <div class="flex justify-center mt-auto">
        <button
          @click="editProfileModal('editName')"
          class="bg-[#58C1E1] rounded-[100px] w-full py-2 px-8 text-white text-sm font-bold"
        >
          Update
        </button>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetEditPhone" :on-close="closeSheetEditPhone">
      <div slot="title">Details</div>
      <div>
        <div class="form-row">
          <label
            data-v-5c3bbc28=""
            style="
              display: flex;
              align-items: center;
              justify-content: flex-start;
            "
          >
            Phone Number</label
          >
        </div>
        <VuePhoneNumberInput
          v-model="editGuardian.phone_number"
          default-country-code="GB"
        />
      </div>
      <div class="flex justify-center mt-auto">
        <button
          @click="editProfileModal('editPhone')"
          class="bg-[#58C1E1] rounded-[100px] w-full py-2 px-8 text-white text-sm font-bold"
        >
          Update
        </button>
      </div>
    </Sheet>

    <Sheet
      :show.sync="sheetEditFamilyUsername"
      :on-close="closeSheetEditFamilyUsername"
    >
      <div slot="title">Details</div>
      <div>
        <AppInput
          maxlength="20"
          :dice="false"
          :required="true"
          v-model="editGuardian.username"
          type="text"
          name="Family User Name"
          label="username"
          labelAlign="left"
        />
      </div>
      <div class="flex justify-center mt-auto">
        <button
          @click="editProfileModal('editFamilyUsername')"
          class="bg-[#58C1E1] rounded-[100px] w-full py-2 px-8 text-white text-sm font-bold"
        >
          Update
        </button>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetEditPassword" :on-close="closeSheetEditPassword">
      <div slot="title">Change password</div>
      <div>
        <div>
          <AppInput
            :dice="false"
            :required="true"
            v-model="editGuardian.password"
            type="password"
            name="New password"
            label="new_password"
            labelAlign="left"
          />
          <AppInput
            :dice="false"
            :required="true"
            v-model="repeat_password"
            type="password"
            name="Repeat password"
            label="repeat_password"
            labelAlign="left"
          />
        </div>
        <!-- <p v-if="passwordMatch" class="passwordsMatch">Passwords do not match</p> -->
        <div class="form-container__requirements">
          <div class="container__requirements__element">
            <p>At least 8 characters</p>
            <img
              v-if="validateItem1"
              src="../../../login/assets/images/check.svg"
              alt="Complete"
            />
            <img
              v-else
              src="../../../login/assets/images/cross.svg"
              alt="Incomplete"
            />
          </div>
          <div class="container__requirements__element">
            <p>At least one uppercase letter</p>
            <img
              v-if="validateItem2"
              src="../../../login/assets/images/check.svg"
              alt="Complete"
            />
            <img
              v-else
              src="../../../login/assets/images/cross.svg"
              alt="Incomplete"
            />
          </div>
          <div class="container__requirements__element">
            <p>At least one number and special character</p>
            <img
              v-if="validateItem3"
              src="../../../login/assets/images/check.svg"
              alt="Complete"
            />
            <img
              v-else
              src="../../../login/assets/images/cross.svg"
              alt="Incomplete"
            />
          </div>
          <div class="container__requirements__element">
            <p>Both passwords match</p>
            <img
              v-if="validateItem4"
              src="../../../login/assets/images/check.svg"
              alt="Complete"
            />
            <img
              v-else
              src="../../../login/assets/images/cross.svg"
              alt="Incomplete"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-auto">
        <button
          :disabled="passwordMatch"
          @click="editProfileModal('editPassword')"
          class="bg-[#58C1E1] rounded-[100px] w-full py-2 px-8 text-white text-sm font-bold"
        >
          Change password
        </button>
      </div>
    </Sheet>
  </div>
</template>

<script>
import { VueSuggestion } from "vue-suggestion";
import itemTemplate from "../../components/ItemTemplate";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import config from "../../config";
import _ from "lodash";
import Sheet from "../../components/Sheet";
// import AppLoader from "../../components/AppLoader";
import Avatar from "vue-avatar-component";
import swal from "sweetalert";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    // LinkAccount: () =>
    //   import(/* webpackChunkName: "LinkAccount" */ "@/components/LinkAccount"),
    AppProfileAdress: () =>
      import(
        /* webpackChunkName: "AppProfileAdress" */ "../../components/AppProfileAdress"
      ),
    AppInput: () =>
      import(/* webpackChunkName: "AppInput" */ "../../components/AppInput"),
    // AddAdress: () =>
    //   import(/* webpackChunkName: "AddAdress" */ "../../components/AddAdress"),
    // AppMyProfileSettings: () =>
    //   import(
    //     /* webpackChunkName: "AppMyProfileSettings" */ "../../components/AppMyProfileSettings"
    //   ),
    VueSuggestion,
    Sheet,
    // AppLoader,
    Avatar,
    VuePhoneNumberInput,
  },
  data() {
    return {
      notifications: false,
      sheetAddAddress: false,
      sheetEditName: false,
      sheetEditPhone: false,
      sheetEditUsername: false,
      sheetEditFamilyUsername: false,
      sheetEditPassword: false,
      active_el: 1,
      cardState: "#general",
      componentKey: 0,
      isDefaultValue: 0,
      userName: "",
      isLoading: true,
      modalAddress: false,
      editAddressModal: false,
      editModal: false,
      isAddressLoading: false,
      selectedAddress: "",
      getApiAddresses: [],
      finalAddress: "",
      hasAddress: false,
      itemTemplate,
      address: {
        street: "",
        number: "",
        type: "0",
        is_default: false,
      },
      editAddress: {
        address: "",
        type: "0",
      },
      editGuardian: {
        phone_number: "",
        first_name: "",
        last_name: "",
        username: "",
        password: "",
      },
      repeat_password: "",
      validation: {
        item_1: false,
        item_2: false,
        item_3: false,
        item_4: false,
      },
    };
  },
  computed: {
    ...mapGetters(["guardian", "addresses"]),
    passwordMatch() {
      if (this.editGuardian.password == this.repeat_password) {
        return false;
      } else {
        return true;
      }
    },
    validateItem1() {
      if (this.editGuardian.password.length >= 8) {
        return true;
      } else {
        return false;
      }
    },
    validateItem2() {
      if (this.editGuardian.password.search(/[A-Z]/)) {
        return false;
      } else {
        return true;
      }
    },
    validateItem3() {
      let regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
      if (regex.test(this.editGuardian.password)) {
        return true;
      } else {
        return false;
      }
    },
    validateItem4() {
      if (
        this.editGuardian.password === this.repeat_password &&
        this.repeat_password.length > 0 &&
        this.editGuardian.password.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchUser",
      "fetchProfile",
      "editGuardianAction",
      "editGuardianAddress",
      "addGuardianAddressAction",
      "deleteGuardianAddressAction",
      "fetchNotificationsAction",
    ]),
    toggleNotifications() {
      this.$store.state.guardianEmail = !this.$store.state.guardianEmail;
      this.notifications = true;
    },
    openSheetAddAddress() {
      this.hasAddress = false;
      this.sheetAddAddress = true;
    },
    closeSheetAddAddress() {
      this.sheetAddAddress = false;
      this.address.street = "";
      this.address.town = "";
      this.address.number = "";
      this.address.postcode = "";
      this.address.country = "";
      this.address.type = "0";
      this.address.is_default = 0;
      this.hasAddress = false;
      this.finalAddress = "";
      this.selectedAddress = "";
      this.$refs.suggest.searchText = "";
      this.getApiAddresses = [];
      this.setDefaultValue(0);
      this.forceRerender();
    },
    openSheetEditName() {
      this.editGuardian.first_name = this.guardian.first_name;
      this.editGuardian.last_name = this.guardian.last_name;
      this.sheetEditName = true;
    },
    closeSheetEditName() {
      this.sheetEditName = false;
    },
    openSheetEditPhone() {
      this.editGuardian.phone_number = this.guardian.phone_number;
      this.sheetEditPhone = true;
    },
    closeSheetEditPhone() {
      this.sheetEditPhone = false;
    },
    openSheetEditFamilyUsername() {
      this.editGuardian.username = this.guardian.username;
      this.sheetEditFamilyUsername = true;
    },
    closeSheetEditFamilyUsername() {
      this.sheetEditFamilyUsername = false;
    },
    openSheetEditPassword() {
      this.editGuardian.password = "";
      this.repeat_password = "";
      this.sheetEditPassword = true;
    },
    closeSheetEditPassword() {
      this.sheetEditPassword = false;
      this.editGuardian.password = "";
      this.repeat_password = "";
    },
    activate(el, state) {
      this.active_el = el;
      this.cardState = state;
    },
    forceRerender() {
      this.componentKey++;
      this.$mount();
    },
    setDefaultValue(val) {
      this.isDefaultValue = val;
    },
    logOutMobile() {
      swal({
        title: "Are you sure?",
        text: "You will be logged out of the application!",
        buttons: true,
        dangerMode: true,
      }).then((willLogout) => {
        if (willLogout) {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("user_type");
          window.localStorage.removeItem("guardian_family_id");
          window.localStorage.removeItem("guardian_setup_active_child_id");
          window.localStorage.removeItem("guardian_wizard_step");
          window.localStorage.removeItem("activeChild");
          window.localStorage.removeItem("activeChildBalance");
          window.localStorage.removeItem("activeChildActivity");
          localStorage.clear();
          location.replace("https://" + window.location.host + "/?logout=true");
        }
      });
    },
    itemSelected(item) {
      this.address.street = item.name;
    },
    setLabel(item) {
      return item.name;
    },
    getInputAddress() {
      // console.log(evt);
    },
    search: _.debounce(function (input) {
      const url = `${config.API_HOST}/googleApiAddress?token=Sonik12345@-()kskhuii90u0iijhbasfj092h9oshdfsdbiuf0-j02&query=${input}`;
      this.isAddressLoading = true;
      axios
        .get(url)
        .then((response) => {
          this.getApiAddresses = [];

          console.log(response);

          response.data.data.forEach((address) => {
            const addArr = {
              name: address,
            };
            this.getApiAddresses.push(addArr);
          });

          if (this.getApiAddresses.length > 0) {
            this.hasAddress = false;
            this.isAddressLoading = false;
          } else {
            this.isAddressLoading = false;
            this.hasAddress = true;
            this.finalAddress = input;
          }

          if (input.length === 0) {
            this.hasAddress = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000),
    closeEditModalAddress() {
      this.editAddressModal = false;
    },
    openEditModalAddress(index) {
      this.editAddressModal = true;
      const addressName = {
        name: this.addresses[index].address,
      };
      this.selectedAddress = this.addresses[index].address;
      this.itemSelected(addressName);
      this.setLabel(addressName);
      this.editAddress.type = this.addresses[index].type;
    },
    addAddress() {
      const { street, number, type, is_default } = this.address;
      const fullAddress = number + " " + street;

      if (street && number && type) {
        const formData = {
          fullAddress,
          type,
          is_default,
        };

        this.addGuardianAddressAction({ formData });
        this.closeSheetAddAddress();
      }
    },
    deleteAddress(id) {
      swal({
        title: "Are you sure?",
        text: "Your address will be deleted!",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deleteGuardianAddressAction(id);
        }
      });
    },
    editProfileModal(type) {
      this.editGuardianAction(this.editGuardian);
      this.closeSheetEditName();

      if (type === "editName") {
        this.closeSheetEditName();
      } else if (type === "editPhone") {
        this.closeSheetEditPhone();
      } else if (type === "editFamilyUsername") {
        this.closeSheetEditFamilyUsername();
      } else if (type === "editPassword") {
        this.closeSheetEditPassword();
      }

      this.editGuardian = {
        id: "",
        first_name: "",
        last_name: "",
        username: "",
        password: "",
      };
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.state.isProfilePage = true;
      next();
    });
  },
  // mounted() {
  //     Promise.all([
  //         this.fetchUser(),
  //         this.fetchProfile(),
  //         this.fetchNotificationsAction(),
  //     ]).then(() => {
  //         this.isLoading = false;
  //     });
  // },
};
</script>

<style lang="scss">
.saveAddressButton {
  background: var(--red);
  border: none;
  width: 100%;
  color: white;
  padding: 15px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  margin: 0 auto;
  cursor: pointer;
}
.vue-suggestion .vs__input-group .vs__input {
  min-height: 44px !important;
  color: var(--main) !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 100px !important;
  padding: 5px 30px;
  font-size: 14px;
  width: 100%;
  background: #ebebeb !important;
  color: #806157 !important;
  display: block;
  border: none !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: unset !important;
}
.vue-suggestion .vs__list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 310px;
  overflow-y: auto;
  border: 1px solid #cecece;
  position: relative;
  margin-top: 3px;

  border-radius: 3px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.vue-suggestion .vs__list .vs__list-item {
  background-color: #fff;
  padding: 15px;
  border-left: 5px solid #36d5f2;
}
.vue-suggestion .vs__list .vs__list-item:last-child {
  border-bottom: none;
}
.vue-suggestion .vs__list .vs__list-item:hover {
  background-color: #eee !important;
}
.vue-suggestion .vs__list,
.vue-suggestion .vs__loading {
  position: absolute;
}
.vue-suggestion .vs__list .vs__list-item {
  cursor: pointer;
}
.vue-suggestion .vs__list .vs__list-item.vs__item-active {
  background-color: #7a615a;
}
.noAddresses {
  background: #f8f5ed;
  color: #7a615a;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}
.defaultAddress {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;

  .vs-checkbox-label {
    font-size: 14px;
    margin-top: 2px;
  }

  .vs-checkbox-con {
    width: 18px;
    height: 18px;
  }

  .vs-checkbox-content {
    display: flex;
    align-items: center;
  }
}

.appContent {
  display: none !important;
}

.addressTypeSelect {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  border: 1px solid #dedede;
  border-radius: 5px;
  outline: 0;
  height: 50px;
  color: #7a615a;
  font-size: 14px;
  background: #fafafa;
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
}

.dashboardProfile {
  height: 100%;
  display: grid;
  grid-template-columns: 10fr 3fr;
}

.dashboardProfile__center {
  width: 100%;
  height: 100%;
  overflow-y: scroll !important;
  padding-right: 10px;
  margin-right: 20px;

  &__mobile {
    display: none;
  }
}

.dashboardProfile__center__stars {
  margin-top: 60px;
}

.dashboardProfile__center__title {
  display: block;

  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 42px;
  color: #7a615a;
}

.dashboardProfile__settings {
  margin-left: 20px;
}

.dashboardProfile__center__delivery {
  margin-top: 80px;
}

.dashboardProfile__center__delivery__title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #7a615a;
  margin-top: 40px;
}

.addAdress__box {
  width: 100% !important;
  height: 300px !important;
  margin-top: 50px;
}

.addAdress__box__btn {
  margin: 0 auto !important;
  margin-top: 50px !important;
}

.dashboardProfile__center__delivery {
  margin-left: 21px;
}

.newModal > .appForm__title {
  top: 0 !important;
}

.newModal > .appForm__description {
  margin-top: 10px;
}
.likes {
  display: flex;
  margin-top: 20px;
}

/*************************************************::placeholder */

.card {
  margin: auto;
  z-index: 1;
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
}

.card[data-state="#general"] {
  .card-main {
    padding-top: 0;
  }
}

.card.isActive {
  .card-header {
    height: 80px;
  }

  .card-buttons {
    z-index: 10;
  }

  .card-cover {
    height: 100px;
    top: -50px;
  }

  .card-avatar {
    transform: none;
    left: 20px;
    width: 50px;
    height: 50px;
    bottom: 10px;
  }

  .card-fullname,
  .card-jobtitle {
    left: 86px;
    transform: none;
  }

  .card-fullname {
    bottom: 28px;
    font-size: 19px;
  }

  .card-jobtitle {
    bottom: 10px;
    letter-spacing: 1px;
    font-size: 10px;
  }
}

.card-header {
  // position: relative;
  display: flex;
  padding-left: 20px;
  flex-direction: row;
  height: 72px;
  flex-shrink: 0;
  width: 100%;
  transition: 0.15s;
  margin-bottom: 35px;
  margin-top: 55px !important;
}

.card-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  height: 160px;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(30px);
  transform: scale(1.2);
  transition: 0.5s;
}

.card-avatar {
  display: inline-block;
  width: 80px;
  height: 80px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
  // border-radius: 50%;
  // object-position: left;
  // object-fit: cover;
  // position: absolute;
  // bottom: 0;
  // left: 50%;
  transform: translateX(-50%) translateY(-64px);
  background: rgba(255, 255, 255, 0.5);
  padding: 5px;
}

.card-fullname {
  // position: absolute;
  // bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  transform: translateY(-10px) translateX(-50%);
  // left: 50%;
  color: var(--secondary);
}

.card-jobtitle {
  // position: absolute;
  // bottom: -2px;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 300;
  margin: 0;
  // left: 50%;
  transform: translateX(-50%) translateY(-7px);
}

.card-main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card-subtitle {
  font-weight: 800;
  font-size: 20px;
}

.card-desc {
  line-height: 1.6;
  color: var(--secondary);
  font-size: 14px;
  margin: 0;
  font-weight: 400;
}

.card-social {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 30px;
  svg {
    fill: rgb(165, 181, 206);
    width: 16px;
    display: block;
    transition: 0.3s;
  }
  a {
    color: #8797a1;
    height: 32px;
    width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    background-color: rgba(93, 133, 193, 0.05);
    border-radius: 50%;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-buttons {
  display: flex;
  margin-top: auto;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;

  button {
    margin: 10px;
    flex: 1 1 auto;
    user-select: none;
    background: 0;
    font-size: 14px;
    font-weight: 800;
    border: 0;
    padding: 8px 2px;
    cursor: pointer;
    color: #01a3c02c;
    font-family: var(--sans) !important;
    transition: 0.3s;
    font-weight: bold;
    outline: 0;
    // border-bottom: 3px solid #c4c4c4;
    border: 1px solid #01a3c02c;
    border-radius: 60px;

    &.isActive,
    &:hover {
      color: #01a4c0;
      border: 1px solid #01a4c0;
    }
  }
}

.card-section {
  display: none;
  &.isActive {
    display: block;
    animation: fadeIn 0.6s both;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translatey(40px);
  }
  100% {
    opacity: 1;
  }
}

.card-timeline {
  margin-top: 30px;
  position: relative;
  &:after {
    background: linear-gradient(
      to top,
      rgba(134, 214, 243, 0) 0%,
      rgba(81, 106, 204, 1) 100%
    );
    content: "";
    left: 42px;
    width: 2px;
    top: 0;
    height: 100%;
    position: absolute;
    content: "";
  }
}

.card-item {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-bottom: 30px;
  z-index: 1;
  &:last-child {
    padding-bottom: 5px;
  }

  &:after {
    content: attr(data-year);
    width: 10px;
    position: absolute;
    top: 0;
    left: 37px;
    width: 8px;
    height: 8px;
    line-height: 0.6;
    border: 2px solid #fff;
    font-size: 11px;
    text-indent: -35px;
    border-radius: 50%;
    color: rgba(#868686, 0.7);
  }
}

.card-item-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}

.card-item-desc {
  font-size: 13px;
  color: #6f6f7b;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
}

.card-settings-wrapper {
  margin-top: 20px;
}

.card-settings {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #6f6f7b;
  font-family: "DM Sans", sans-serif;
  line-height: 1.6;
  cursor: pointer;

  & + & {
    margin-top: 16px;
  }

  svg {
    flex-shrink: 0;
    width: 30px;
    min-height: 34px;
    margin-right: 12px;
    transition: 0.3s;
    padding-right: 12px;
    border-right: 1px solid #dfe2ec;
  }
}

.form-container__requirements {
  margin: 30px 0 50px 0;
}

.container__requirements__element {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.8rem;
  word-wrap: break-word;
  color: #979797;
  margin-bottom: 5px;
}

.container__requirements__element img {
  margin-left: 20px;
}

.passwordsMatch {
  margin-top: 20px;
  color: #ff4f79;
  text-align: center;
  font-size: 0.8rem;
}
.card-avatar-new {
}
.fullname {
  font-size: 20px;
  font-weight: 800;
}
.email {
  font-size: 14px;
  font-weight: 300;
}
.input-label {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
}
</style>
