<template id="bottomSheet-template">
  <div class="bottomsheet">
    <div
      class="glass"
      :class="{ open: show }"
      @click="close"
    />
    <div
      class="bottomsheet-body"
      :class="{ open: show }"
    >
      <div class="bottomsheet-header">
        <div class="w-[25px] h-[25px]" />
        <div class="bottomsheet-title">
          <slot name="title" />
        </div>
        <span>
          <slot name="buttons" />
          <button
            class="sheet-close"
            @click="close"
          >
            <img src="../assets/images/close.png" alt="Close">
          </button>
        </span>
      </div>
      <div class="bottomsheet-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "onClose"],
  methods: {
    close() {
      this.onClose();
    }
  },
  ready: function() {
    document.addEventListener("keydown", e => {
      if (this.show && e.keyCode === 27) {
        this.onClose();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.bottomsheet {
  // .glass {
  //   z-index: 99999;
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background: hsla(0, 0%, 0%, 0.5);
  //   opacity: 0;
  //   pointer-events: none;
  //   transition: opacity 0.3s ease;
  //   //will-change: opacity;

  //   &.open {
  //     pointer-events: initial;
  //     opacity: 1;
  //   }
  // }

  .bottomsheet-body {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background: #fff;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.33);
    transform: translateY(100%);
    z-index: 100;
    transition: opacity 0.3s ease, transform 0.3s ease;
    //will-change: opacity, transform;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    // padding: 0 0 1rem 1rem;
    // border-top-left-radius: 14px;
    // border-top-right-radius: 14px;

    &.open {
      opacity: 1;
      transform: translateY(0);
    }

    .bottomsheet-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 3rem;
      flex: 0 0 3rem;
      padding: 0.5rem 1rem 0.5rem 1rem;

      button {
        margin-right: 0;
      }

      .sheet-close {
        width: 25px;
        height: 25px;
        border: none;
        /*background: var(--red);*/
        border-radius: 50%;
        padding: 4px;
      }
    }

    .bottomsheet-title {
      font-size: 0.9rem;
      line-height: 1.6rem;
      font-weight: bold;
    }

    .bottomsheet-content {
      flex: 1;
      overflow: auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
  }
}
</style>
